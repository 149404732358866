import * as utils from '../../shared/utils';

export default {
  name: 'utils',
  proto: {
    utils,
  },
  static: {
    utils,
  },
};
