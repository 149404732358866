import { getWindow } from 'ssr-window';
import $ from '../../shared/dom7';
import { extend, nextTick } from '../../shared/utils';
import Modal from '../modal/modal-class';
/** @jsx $jsx */
import $jsx from '../../shared/$jsx';

class Toast extends Modal {
  constructor(app, params) {
    const extendedParams = extend(
      {
        on: {},
      },
      app.params.toast,
      params,
    );

    // Extends with open/close Modal methods;
    super(app, extendedParams);

    const toast = this;
    const window = getWindow();

    toast.app = app;

    toast.params = extendedParams;

    const { closeButton, closeTimeout } = toast.params;

    let $el;
    if (!toast.params.el) {
      // Find Element
      const toastHtml = toast.render();

      $el = $(toastHtml);
    } else {
      $el = $(toast.params.el);
    }

    if ($el && $el.length > 0 && $el[0].f7Modal) {
      return $el[0].f7Modal;
    }

    if ($el.length === 0) {
      return toast.destroy();
    }

    extend(toast, {
      $el,
      el: $el[0],
      type: 'toast',
    });

    $el[0].f7Modal = toast;

    if (closeButton) {
      $el.find('.toast-button').on('click', () => {
        toast.emit('local::closeButtonClick toastCloseButtonClick', toast);
        toast.close();
      });

      toast.on('beforeDestroy', () => {
        $el.find('.toast-button').off('click');
      });
    }

    let timeoutId;
    toast.on('open', () => {
      $('.toast.modal-in').each((openedEl) => {
        const toastInstance = app.toast.get(openedEl);
        if (openedEl !== toast.el && toastInstance) {
          toastInstance.close();
        }
      });
      if (closeTimeout) {
        timeoutId = nextTick(() => {
          toast.close();
        }, closeTimeout);
      }
    });
    toast.on('close', () => {
      window.clearTimeout(timeoutId);
    });

    if (toast.params.destroyOnClose) {
      toast.once('closed', () => {
        setTimeout(() => {
          toast.destroy();
        }, 0);
      });
    }

    return toast;
  }

  render() {
    const toast = this;
    if (toast.params.render) return toast.params.render.call(toast, toast);
    const {
      position,
      horizontalPosition,
      cssClass,
      icon,
      text,
      closeButton,
      closeButtonColor,
      closeButtonText,
    } = toast.params;
    const horizontalClass =
      position === 'top' || position === 'bottom' ? `toast-horizontal-${horizontalPosition}` : '';
    return (
      <div
        class={`toast toast-${position} ${horizontalClass} ${cssClass || ''} ${
          icon ? 'toast-with-icon' : ''
        }`}
      >
        <div class="toast-content">
          {icon && <div class="toast-icon">{icon}</div>}
          <div class="toast-text">{text}</div>
          {closeButton && !icon && (
            <a class={`toast-button button ${closeButtonColor ? `color-${closeButtonColor}` : ''}`}>
              {closeButtonText}
            </a>
          )}
        </div>
      </div>
    );
  }
}
export default Toast;
