/* eslint no-param-reassign: "off" */
import request from '../../shared/request';

export default {
  name: 'request',
  proto: {
    request,
  },
  static: {
    request,
  },
};
